@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins/poppins.regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-medium";
  src: url("../fonts/Poppins/poppins.medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins/poppins.bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-extra-bold";
  src: url("../fonts/Poppins/poppins.extrabold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-thin";
  src: url("../fonts/Poppins/Poppins-Thin_a2b33b7bc32dc193e1119e792dc8d604.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins/Poppins\ Light\ 300.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-extra-light";
  src: url("../fonts/Poppins/Poppins\ ExtraLight\ 275.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-semibold";
  src: url("../fonts/Poppins/Poppins-SemiBold_7c466ac270132dae4bf27ce8a2b2e0b7.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.module-name {
  // font: 20px/26px "Poppins";
  font-family: "poppins-medium";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.purpule-button,
.purpule-button:hover {
  background-color: #804fd9;
  border-color: #804fd9;
  box-shadow: unset;
  color: #ffffff !important;
}

.btn {
  border-radius: 4px;
}
.table-custom {
  th {
    background-color: #f5f6f6 !important;
  }
  .form-check-input {
    border: 1px solid #8b919e !important;
  }
}
.filter-icon {
  font-size: 20px;
}

body {
  font-family: "poppins-regular";
}
.custom-header-title {
  font-family: "poppins-bold";
}
.text-black {
  // color: #000000 !important;
  opacity: 1;
}
.custom-tabs {
  background-color: #ffffff;
  // box-shadow: 0px 3px 4px #0000001c;
  // border: 1px solid #e6e6e6;
  padding: 12px 1.5rem !important;
  padding-left: 10px !important;
  box-shadow: unset;
  border: unset;
  position: relative;
  .nav-item {
    a {
      color: #8b919e;
    }
  }
  .edit-button {
    position: absolute;
    right: 25px;
    top: 50%;
    z-index: 1 !important;
    transform: translateY(-50%);
    color: #ffffff !important;
  }
}
.nav-pills .nav-link.active {
  background-color: #e2ebfb;
  border: 1px solid #4f82d9;
  color: #4f82d9;
}
.no-shadow {
  box-shadow: unset;
}

// body .leftside-menu{

//     background-color: #ffffff !important;
// }
.card-heading {
  // font: normal normal 17px/20px "Poppins-Medium";
  color: #2a2140;
  font-family: "Poppins-Medium";
font-size: 17px;
font-weight: 600;
line-height: 24px;
}
.letter-spacing {
  letter-spacing: 5.2px;
}
.border-solid {
  border: 1px solid #e6e6e6;
}
.border-radius {
  border-radius: 8px;
}
.font-medium {
  color: #1c1c1e;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.font-regular {
  color: #606060;
  font-family: "Poppins-regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.font-semibold {
  color: #1c1c1e;
  font-family: "Poppins-Semibold";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.section-heading {
  font: normal normal 16px/19px "Poppins-Bold";
  letter-spacing: 0px;
  color: #2a2140;
}

.same-height {
  overflow-y: auto;
  overflow-x: hidden;
}
.same-height-horizontal {
  overflow-y: auto;
}
.actionIcons {
  width: 15px;
  display: inline-block;
  font-size: 15px;
  margin: 5px;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 6px;
  // display: none;
}
// ::-webkit-scrollbar:hover{
//     display: block;
// }
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
.form-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #fa5c7c;
}

input[type="date"] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
  content: "\1F4C5"; 
  position: absolute;
  top: 5px;
  right: 5px;
  width: auto;
  height: auto;
  color: #555;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
  color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
  z-index: 1;
}

.font-bold {
  font-family: "Poppins-Bold";
}

.underline {
  text-decoration: underline !important;
}
.text-blue {
  color: #2275d4 !important;
}
.btn-login,
.btn-login:hover {
  background-color: #244180 !important;
  box-shadow: unset !important;
}
.no-border {
  border: 0px !important;
}
body.authentication-bg {
  background-image: url("../images/login-bg.png");
}
.account-pages {
  .greyImag {
    filter: grayscale(100%);
    opacity: 0.8;
    margin-right: 5px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .fs-12 {
    font-size: 10px;
  }
  .card-footer {
    justify-content: flex-start;
  }
  a {
    color: #000000;
    text-decoration: none;
  }
  .background-style {
    background-position: 0% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    border-radius: 4px;
    background-image: linear-gradient(90deg, #c3b2ff 0%, #ffbfb8 100%);
    background-size: 100% 25%;
    background-color: transparent;
  }
  .card {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-dbc7ff);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #c6bfff;
    border: 1px solid #dbc7ff;
    border-radius: 22px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
  }
  .card-footer {
    border-radius: 22px;
  }
  .stats {
    list-style-type: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      margin: 0 10px;
      position: relative;
      &:not(:last-child)::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #e6e6e6;
        top: 0;
        right: -20px;
      }
    }

    .fs-20 {
      font-size: 20px;
    }
    .fs-14 {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .align-item-center {
    align-items: center;
  }
  .opac-bg {
    background: rgba(255, 255, 255, 0.5);
  }
  .custom-height {
    min-height: 400px;
  }
}
.side-nav .menuitem-active > a {
  background-color:#1B285A !important;
  // color: #ffffff !important;
}
.side-nav {
  li.side-nav-item {
    .side-nav-second-level {
      .menuitem-active {
        > a {
          background-color: transparent !important;
          color: #ffffff !important;
        }
      }
    }
  }
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav {
  li.side-nav-item {
    .side-nav-second-level {
      .menuitem-active {
        > a {
          background-color: transparent !important;
          color: #f0e8ff !important;
        }
      }
    }
  }
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background-color: #1B285A!important;
  // color: #000000 !important;
}
.related-data-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 3px #a08cd233;
  border: 1px solid #e6e6e6;
  .related-data-heading {
    background: #f5f6f6 0% 0% no-repeat padding-box;
    font-family: "Poppins-Semibold";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #2a2140;
  }
  .related-data {
    font: "Poppins-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;

    color: #2a2140;
  }
}
.card-body {
  .text-end {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 9;
  }
}
.justify-content-flex-end {
  justify-content: flex-end;
}

.form-check-input:disabled {
  opacity: 1;
}
.fs-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.actionDropDown {
  margin-top: 0px;
  margin-bottom: 0px;
  .dropdown-menu {
    padding: 0;
  }
  .dropdown-item {
    padding: 0;
    margin: 5px 0;
    button {
      width: 100%;
    }
  }
}

#checkersRemark {
  min-height: 150px !important;
}

.nav-user {
  font-family: "poppins-regular";
  color: #000000 !important;
  padding: 10px !important;
}

.help-box {
  background: #804fd9;
}
.filterBody {
  min-width: 350px;
}

#dropdown-profile {
  min-width: 70px !important;
  text-align: center !important;
  position: relative;
}
.account-user-name {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  width: 100%;
  display: block !important;
  left: 0;
}

//steps css

.steps {
  list-style: none;
  display: flex;
  margin: 0px;
  padding: 20px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;

  .step {
    // width: 200px;
    padding-left: 20px;
    position: relative;
    color: #8b919e;
    margin-right: 30px;
  }
  .active,
  .completed {
    color: #000000;
  }
}
.step::before {
  content: " ";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #e6e6e6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.step.active::before {
  content: " ";
  border: 2px solid #804fd9;
}

.step:not(:last-child)::after {
  content: " ";
  width: 2px;
  height: 70%;
  background-color: #e6e6e6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
}
.step.active::after,
.step.completed::after {
  background-color: #804fd9;
}

.completed::before {
  content: " ";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #804fd9;
  border: 2px solid #804fd9;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.downLoadSampleFile,
.downLoadSampleFile:hover {
  display: block;
  text-align: right;
  width: 100%;
  color: #804fd9;
}
.manuallyEntry {
  display: block;
  color: #4f82d9;
  text-align: right;
  width: 100%;
}
.related-data {
  cursor: pointer;
}
.noShadow {
  .card {
    background-color: transparent !important;
  }
}

.loader {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  align-items: center;
  .spinner-border {
    width: 15px;
    height: 15px;
  }
}
.errorToastRightTop {
  position: absolute;
  top: 50px;
  right: 0;
  width: 50%;
  z-index: 1 !important;
  .alert-danger {
    background-color: rgba(250, 92, 124, 1);
    border-color: rgba(250, 92, 124, 1);
    color: #ffffff;
  }
}
.position-relative {
  position: relative;
  .alert-danger {
    background-color: rgba(250, 92, 124, 1);
    color: #ffffff;
  }
}

.dropdown hr {
  margin: 0;
}
.dropdown {
  .clearFilter {
    color: #804fd9;
  }
}
.cursor {
  cursor: pointer;
}
.searchIcon {
  position: absolute;
  top: 35px;
  right: 10px;
  color: #000000;
  font-size: 18px;
  cursor: pointer;
}
.Dropdowndetails .btn-primary {
  background: #804fd9;
  border: 1px solid#804fd9;
}
.Dropdowndetails .active {
  background: #804fd9;
  border: 1px solid#804fd9;
}

.editlink-button {
  background-color: unset;
  border-color: unset;
  border: unset;
  box-shadow: unset;
  color: #98a6ad !important;
  font-size: 1.2rem;
  &:hover {
    background-color: unset;
    border-color: unset;
    border: unset;
    box-shadow: unset;
    color: #919ca7 !important;
  }
}
.spinnerWraper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error-modal {
  background-color: #e85e6c;
  display: block;
}
.error-modal .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.err-modal,
.err-modal h2 {
  color: #434e65;
  border: 0px;
}
.err-modal {
  .modal-content {
    border: 0px;
    border-radius: 10px;
  }
  .fs-20 {
    font-size: 50px;
  }
  .mdi {
    color: #ffff;
  }
  .erro-message {
    text-transform: capitalize;
  }
}

.success-modal {
  background-color: #0acf97;
  display: block;
}
.success-modal .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffffff;
}

.err-modal,
.err-modal h2 {
  color: #434e65;
  border: 0px;
}
.err-modal {
  .modal-content {
    border: 0px;
    border-radius: 10px;
  }
  .fs-20 {
    font-size: 50px;
  }
  .mdi {
    color: #ffff;
  }
  .erro-message {
    text-transform: capitalize;
  }
}
.modal-right {
  .react-select__single-value.css-qc6sy-singleValue {
    color: var(--ct-form-select-color) !important;
  }
  
}
.Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* text-align: center; */
  background: rgba(255,255,255,0.5);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* styles.css */
.custom-multiselect {
  background-color: transparent;
  /* border: 1px solid rgb(95, 65, 65); */
  border-radius: 4px;
  width: 100%; /* Set the width as needed */
  color: black;
  /* Add more custom styles here */
}


 .multiSelectContainer li:hover {
  background: "red";
  border-radius: 4px;
  width: 100%; /* Set the width as needed */
  /* Add more custom styles here */
}


.multiSelectContainer li:hover {
  background:  #eef2f7;
  color: black;
  cursor: pointer;
}

.chip{
  background:  #804fd9;

}

